import React from "react"
import {makeStyles} from "@mui/styles";
import cx from "classnames";
import {StaticImage} from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
    blockContainer: {
        position: 'absolute',
        height: 'calc(100dvh - 52px)',
        width: 'calc(100vw - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 1,
        transition: 'opacity 1200ms linear',
        overflow: 'hidden',
        padding: '0 32px',

        [theme.breakpoints.up('mobile')]: {
            height: 'calc(100dvh - 100px)',
        },
        [theme.breakpoints.down('laptop')]: {
            width: '100vw',
        },
    },
    hideBlock: {
        opacity: 0,
        transition: 'opacity 600ms linear',
    },
    textContentTitle: {
        color: theme.palette.grey.second,
        fontFamily: 'Oswald',
        fontSize: 48,
        lineHeight: 1.1,
        fontWeight: 500,
        textTransform: "uppercase",
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        width: '100vw',

        [theme.breakpoints.up('mobile')]: {
            fontSize: 72,
            lineHeight: 1.3,
        },
        [theme.breakpoints.up('tablet')]: {
            fontSize: 96,
            padding: '30px 0',
            width: null,
            lineHeight: null,

            "@media (max-height: 800px)": {
                padding: '25px 0',
                fontSize: 86,
            },

            "@media (max-height: 700px)": {
                padding: '20px 0',
                fontSize: 76,
            },
        },
        [theme.breakpoints.up('laptop')]: {
            fontSize: 120,
            padding: '50px 0',
            width: 'calc(100vw - 100px)',

            "@media (max-height: 800px)": {
                padding: '25px 0',
                fontSize: 86,
            },

            "@media (max-height: 700px)": {
                padding: '20px 0',
                fontSize: 76,
            },
        },
    },
    textContentDescription: {
        paddingTop: 8,
        paddingBottom: 20,
        color: theme.palette.grey.second,
        fontFamily: 'Montserrat',
        fontSize: 12,
        lineHeight: 1.3,
        textAlign: 'center',
        maxWidth: 1010,

        [theme.breakpoints.up('mobile')]: {
            fontSize: 16,
        },
    },
    imageContainer: {
        flex: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        position: 'relative',
        justifyContent: 'center',
    },
    image: {
        width: 'auto',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        top: 0,

        '& div[data-placeholder-image]': {
            backgroundColor: '#0E0E0E !important',
        },

        [theme.breakpoints.only('tablet')]: {
            width: '100%',
            height: 'auto',
        },

        [theme.breakpoints.down('tablet')]: {
            width: 'calc(100vw - 64px)',
            height: 'calc(100vw - 64px)',
        },
        '& img': {
            objectFit: 'cover !important',

            [theme.breakpoints.up('tablet')]: {
                objectFit: 'contain !important',
            },
        }
    }
}));

const FourthBlock = ({isVisible, blockRef}) => {
    const classes = useStyles();
    return (
        <div ref={blockRef}
             className={cx(classes.blockContainer, {
                 [classes.hideBlock]: !isVisible
             })}
             style={{zIndex: isVisible ? 1 : 0}}
        >
            <div className={classes.textContentTitle}>{'Who we are'}</div>
            <div className={classes.textContentDescription}>
                {'We’re a team of 20 game developers, artists, and designers who are focused on getting the job done and meeting project goals. We’re passionate about games and always willing to take that extra step to make a project truly shine.'}
            </div>
            <div className={classes.imageContainer}>
                <StaticImage src={"../../../../assets/team_photo.webp"}
                             alt="team_photo"
                             className={classes.image}
                />
            </div>
        </div>
    )
}

export default FourthBlock
